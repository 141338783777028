import DesktopAppList from "../../components/Assets/DesktopAppList";
import PageTitle from "../../components/PageTitle";

export default function AdminAssetsListPage() {
    return (
        <div className="flex flex-col gap-4">
            <section role="heading" className="grid grid-cols-1 xl:grid-cols-2">
                <PageTitle className="grow">Assets</PageTitle>
            </section>
            <DesktopAppList isAdmin={true} />
        </div>
    )
}