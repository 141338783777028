import { FormEvent, useState } from "react";
import { Experience } from "../../types/Experience";
import useExperienceService from "../../services/ExperienceService";
import { useMe } from "../../context/MeContext";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import PositiveButton from "../PositiveButton";
import { Company } from "../../types/Company";
import useCompanyService from "../../services/CompanyService";

interface NewCompanyFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSaved?: (company: Company) => void;
  onCancel?: () => void;
}

export default function NewCompanyForm({
  onSaved,
  open,
  setOpen,
  onCancel,
}: NewCompanyFormProps) {
  const { me } = useMe();

  const [formData, setFormData] = useState({
    name: "",
    termsChecked: false,
  });

  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const { registerCompany } = useCompanyService();

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (formData.name.trim() === "") {
      newErrors.name = "Company name is required";
      isValid = false;
    }

    if (!formData.termsChecked) {
      newErrors.termsChecked = "You must agree to the terms";
      isValid = false;
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  const saveCompany = async (e: FormEvent) => {
    e.preventDefault();

    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    try {
      const newCompany = await registerCompany(formData.name);

      if (onSaved) {
        onSaved(newCompany);
      }

      setFormData({
        name: "",
        termsChecked: false,
      });

      if (!onSaved) {
        setOpen(false);
      }
    } catch (error) {
      const newErrors: any = {
        name: "Failed to save company. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-slate-900 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <DialogTitle
              as="h3"
              className="text-base leading-6 text-white pb-4"
            >
              Register a new company
            </DialogTitle>
            <form
              onSubmit={saveCompany}
              className="card p-8 flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
            >
              {/* Company Name */}
              <div className="sm:col-span-4">
                <label htmlFor="name" className="block leading-6">
                  Name:
                </label>
                <div className="mt-2">
                  <input
                    disabled={isSaving}
                    type="text"
                    name="name"
                    placeholder="Company name *"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                      "name"
                    )}`}
                  />
                </div>
                {validationError("name") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("name")}
                  </div>
                )}
              </div>

              {/* Terms */}
              <div className="sm:col-span-4">
                <div className="mt-2 flex flex-row gap-4">
                  <input
                    disabled={isSaving}
                    type="checkbox"
                    name="termsChecked"
                    value={formData.termsChecked.toString()}
                    onChange={handleInputChange}
                    className="w-6 h-6"
                  />
                  <p>
                    I agree to the{" "}
                    <a
                      href="https://beamxr.io/developerterms/"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      developer terms and conditions
                    </a>{" "}
                    for this organisation.
                  </p>
                </div>
                {validationError("termsChecked") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("termsChecked")}
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="sm:col-span-4 flex items-center gap-2 justify-end">
                <PositiveButton type="submit" disabled={isSaving}>
                  {isSaving ? "Registering..." : "Register"}
                </PositiveButton>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
