import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useCallback,
    useEffect,
  } from "react";
import { Company, Me } from "../types/Me";
import { useMe } from "./MeContext";
import CreateFirstOrganisation from "../components/CreateFirstOrganisation";
import useApiClient from "../services/ApiClient";
import useMeService from "../services/MeService";
  
  // Define the shape of the context
  interface OrganisationConfirmContextType {
    
  }
  
  // Define props for the provider
  interface OrganisationConfirmProviderProps {
    children: ReactNode;
  }
  
  // Create the context
  const OrganisationConfirmContext = createContext<OrganisationConfirmContextType | undefined>(undefined);
  
  export const OrganisationConfirmProvider: React.FC<OrganisationConfirmProviderProps> = ({
    children,
  }) => {
    const [organisation, setOrganisation] = useState<Company | null>(null);
    const {me, refreshMe} = useMe();

    useEffect(() => {

      if (!me) {
        return;
      }

      if (me.memberships.length > 0) {
        setOrganisation(me.memberships[0].company);
      }
    }, [me]);

    const onSuccessfulCreation = () => {
      console.log("Organisation created successfully");
      refreshMe().then((newMe) => {
        if (newMe && newMe.memberships.length > 0) {
          setOrganisation(newMe.memberships[0].company);
        }
      });
    };

    return (
      <OrganisationConfirmContext.Provider value={{}}>
        {organisation != null && children}
        {organisation == null && (
          <div className="md:fixed inset-0 flex items-center md:justify-center z-50 overflow-y-auto overflow-x-hidden p-5">
            <div className="block">
              <CreateFirstOrganisation onSuccessfulCreation={onSuccessfulCreation} />
            </div>
          </div>
        )}
      </OrganisationConfirmContext.Provider>
    );
  };