import { Asset, AssetVersion } from "../../types/Asset";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from '@mui/icons-material/Loop';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SimpleAlert from "../SimpleAlert";
import { useState } from "react";
import useAssetService from "../../services/AssetService";

interface DesktopAppVersionProps {
  app: Asset;
  version: AssetVersion;
    onDelete?: (version: AssetVersion) => void;
}

export default function DesktopAppVersion({
  app,
  version,
    onDelete,
}: DesktopAppVersionProps) {

    const {getNamedAsset, deleteAssetVersion, deleteAsset, getAssetDownloadUrl} = useAssetService();
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [isActing, setIsActing] = useState(false);

    const versionNumber = version.metadata.find((m) => m.key === "version")?.value ?? "";
    const createdAtDate = new Date(version.createdAt);

  // Format the date and time
  const formattedDate = createdAtDate.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = createdAtDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  const deleteThisVersion = async () => {

    setIsActing(true);

    try {

        // Get an updated version of the asset
        const updatedAsset = await getNamedAsset("BeamXR-Desktop-Application");

        // If there are no versions left, delete the asset.
        if (updatedAsset?.versions.length === 1) {
            await deleteAsset(app.id);
        } else {
            await deleteAssetVersion(app.id, version.versionNumber);
        }

        if (onDelete) {
            onDelete(version);
        }
    } catch (error) {
      console.error("Error deleting version:", error);
    }

    setDeleteAlertOpen(false);
    
  }

  const download = async () => {
    setIsActing(true);
    const url = await getAssetDownloadUrl(app.id, version.versionNumber)
    setIsActing(false);
    window.open(url, "_blank");
  }

  const colsClassName = onDelete ? "grid grid-cols-2" : "grid grid-cols-1";

  return (
    <>
    <SimpleAlert
        open={deleteAlertOpen}
        setOpen={() => {
            if (!isActing) {
                setDeleteAlertOpen(false);
            }
        }}
        title={`Are you sure you want to delete ${versionNumber}?`}
        message="This action cannot be undone."
        confirmText={`Delete ${versionNumber}`}
        onConfirm={deleteThisVersion}
      />
    <div className="w-full card flex flex-col">
      <div className="grow">
        <div className="p-4">
          <h3 className="text-2xl">{versionNumber}</h3>
          <p>
            {" "}
            {formattedDate} at {formattedTime}
          </p>
          <p>{version.description}</p>
        </div>
      </div>
      <div className={colsClassName}>
        <button
          disabled={isActing}
          className={`enabled:text-cyan-300 enabled:hover:bg-cyan-300/25 p-2 rounded-none rounded-bl ${onDelete === undefined ? 'rounded-br' : ''} no-transform`}
          onClick={() => {
            download();
          }}
        >
          <ArrowDownwardTwoToneIcon />
          Download
          {isActing && <span className="ml-2"><HourglassEmptyIcon className="animate-spin text-white" style={{ fontSize: 20 }} /></span>}
        </button>
        {onDelete && (
            <button
            disabled={isActing}
            className="enabled:text-red-500 enabled:hover:bg-red-500/25 p-2 rounded-none rounded-br no-transform"
            onClick={() => {
              setDeleteAlertOpen(true);
            }}
          >
            <DeleteIcon />
            Delete
            {isActing && <span className="ml-2"><HourglassEmptyIcon className="animate-spin text-white" style={{ fontSize: 20 }} /></span>}
          </button>)}
      </div>
    </div>
    </>
  );
}
