import { Asset, AssetAndUploadUrl, UploadAsset } from "../types/Asset";
import { HttpError } from "../types/Error";
import useApiClient from "./ApiClient";
import axios, { AxiosRequestConfig } from "axios";

const useAssetService = () => {
    const {get, post, put, delete: del} = useApiClient();

    const getNamedAsset = async (assetName: string): Promise<Asset | null> => {
        try {
            const result = await get<Asset>(`/assets/named/${assetName}`);
            // Check if the response is an error.
            if ((result as HttpError).errorCode) {

                if ((result as HttpError).errorCode === 404) {
                    return null;
                }

                throw (result as HttpError).description;
            }
            
            return result as Asset;
        } catch (error) {
            console.error('Error getting named asset:', error);
            throw error;
        }
    }

    const getAssetDownloadUrl = async (assetId: string, versionId: number): Promise<string> => {
        try {
            const result = await get<string>(`/assets/${assetId}/downloadUrl/${versionId}`);
            if ((result as HttpError).errorCode) {
                throw (result as HttpError).description;
            }
            return result as string;
        } catch (error) {
            console.error('Error getting asset download URL:', error);
            throw error;
        }
    }

    const deleteAssetVersion = async (assetId: string, versionId: number): Promise<void> => {
        try {
            const result = await del(`/admin/assets/${assetId}/version/${versionId}`);
            if ((result as HttpError).errorCode) {
                throw (result as HttpError).description;
            }
        } catch (error) {
            console.error('Error deleting asset version:', error);
            throw error;
        }
    }

    const deleteAsset = async (assetId: string): Promise<void> => {
        try {
            const result = await del(`/admin/assets/${assetId}`);
            if ((result as HttpError).errorCode) {
                throw (result as HttpError).description;
            }
        } catch (error) {
            console.error('Error deleting asset:', error);
            throw error;
        }
      };

    const uploadDesktopApp = async (
        version: string,
        whatsNew: string,
        file: File
      ): Promise<Asset> => {
        try {
          // Prepare the body for the initial API request
          const body: UploadAsset = {
            type: "BeamXR-Desktop-Application",
            version,
            description: whatsNew,
            mimeType: "application/vnd.microsoft.portable-executable",
          };
      
          // Post request to get the asset and upload URL
          const result = await post<AssetAndUploadUrl>("/admin/assets/upload/named", body);
      
          if ((result as HttpError).errorCode) {
            throw (result as HttpError).description;
          }
      
          const asset = result as AssetAndUploadUrl;
      
          // Upload the file in 4MB chunks
          const CHUNK_SIZE = 4 * 1024 * 1024; // 4MB
          const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
          let uploadedBytes = 0;
      
          for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            // Calculate chunk boundaries
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
      
            // Slice the file to get the current chunk
            const chunk = file.slice(start, end);
      
            // Prepare Axios configuration
            const axiosConfig: AxiosRequestConfig = {
              headers: {
                "x-ms-blob-type": "BlockBlob"
              },
            };
      
            // Upload the chunk
            await axios.put(asset.uploadUrl, chunk, axiosConfig);
      
            // Update progress
            uploadedBytes += chunk.size;
            console.log(`Uploaded chunk ${chunkIndex + 1} of ${totalChunks}`);
            if (uploadedBytes === file.size) {
              console.log(`Upload complete!`);
            }
          }
      
          return asset.asset;
      
        } catch (error) {
          console.error("Error uploading desktop app:", error);
          throw error;
        }
      };

    return {getNamedAsset, uploadDesktopApp, deleteAssetVersion, deleteAsset, getAssetDownloadUrl};
}

export default useAssetService;