import { useState, useEffect } from 'react';
import LoopIcon from '@mui/icons-material/Loop';
import LoadingEllipsis from './LoadingEllipsis';

interface AuthLoadingProps {
    message: string;
}

export default function AuthLoading({message}: AuthLoadingProps) {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-30">
            <div className="flex flex-col items-center space-y-4">
                <img src="/img/logo-dev.svg" alt="BeamXR Developer Portal" />
                <h1 className="text-lg text-white">
                    {message}
                    <LoadingEllipsis />
                </h1>
                <LoopIcon 
                    className="animate-spin text-white" 
                    style={{ fontSize: 40 }} 
                />
            </div>
        </div>
    );
}
