import React, { useEffect } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import AuthLoading from "./components/AuthLoading";
import { MeProvider } from "./context/MeContext";
import RoutedApp from "./RoutedApp";
import Navigation from "./components/Navigation";
import { OrganisationConfirmProvider } from "./context/OrganisationConfirmContext";

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0();
  
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect(); // Redirect to login if not authenticated
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <AuthLoading message="Loading your developer experience" />; // Show a loading page while auth is being checked
  }

  if (error) {
    return <div>Oops... {error.message}</div>; // Show an error
  }

  if (!isAuthenticated) {
    return null; // Return nothing if the user is being redirected
  }

  return (
    <MeProvider>
      <OrganisationConfirmProvider>
        <RoutedApp />
      </OrganisationConfirmProvider>
    </MeProvider>
  );
}

export default App;
