import { useEffect, useState } from "react";
import { Asset, AssetVersion } from "../../types/Asset";
import useAssetService from "../../services/AssetService";
import Ghost from "../Ghost";
import PositiveButton from "../PositiveButton";
import DesktopAppForm from "./DesktopAppForm";
import DesktopAppVersion from "./DesktopAppVersion";

interface DesktopAppListProps {
  isAdmin?: boolean;
}

export default function DesktopAppList(
  { isAdmin = false }: DesktopAppListProps
) {
  const [desktopApp, setDesktopApp] = useState<Asset | null>(null);
  const [fetching, setFetching] = useState(false);
  const [fetchRequired, setFetchRequired] = useState(true);
  const { getNamedAsset } = useAssetService();
  const [newAssetFormOpen, setNewAssetFormOpen] = useState(false);
  const [suggestedVersion, setSuggestedVersion] = useState<string>("1.0.0");
  const [isActing, setIsActing] = useState(false);

  useEffect(() => {
    if (!fetchRequired) {
      return;
    }

    setFetchRequired(false);

    setFetching(true);

    getNamedAsset("BeamXR-Desktop-Application")
      .then((asset) => {
        setDesktopApp(asset);
        setFetching(false);
      })
      .catch((error) => {
        console.error("Error getting desktop app:", error);
        setFetching(false);
      });
  }, [desktopApp, fetchRequired]);

  const handleNewVersionSubmitted = (asset: Asset) => {
    setFetchRequired(true);
    setNewAssetFormOpen(false);
  };

  const onDelete = (version: AssetVersion) => {
    setFetchRequired(true);
    setNewAssetFormOpen(false);
  }

  return (
    <>
      <DesktopAppForm
        suggestedVersion={suggestedVersion}
        open={newAssetFormOpen}
        setOpen={setNewAssetFormOpen}
        onSaved={handleNewVersionSubmitted}
      />
      <div className="flex flex-col gap-4">
        <section role="heading" className="flex flex-col gap-4">
          <h2 className="sm:text-xl xl:text-2xl">Desktop App</h2>
          {fetching && (
            <>
              <Ghost additionalClasses="rounded h-36" />
              <Ghost additionalClasses="rounded h-36" />
              <Ghost additionalClasses="rounded h-36" />
              <Ghost additionalClasses="rounded h-36" />
            </>
          )}
          {!fetching && desktopApp && (
            <>
              {desktopApp.versions &&
                desktopApp.versions.length > 0 &&
                desktopApp.versions.map((version, idx) => <DesktopAppVersion key={idx} onDelete={isAdmin ? onDelete : undefined} app={desktopApp} version={version} />)}
            </>
          )}
          {!fetching && !desktopApp && <p>No desktop app found</p>}
          {!fetching && isAdmin && (
            <PositiveButton onClick={() => setNewAssetFormOpen(true)}>
              Upload new desktop app version
            </PositiveButton>
          )}
        </section>
      </div>
    </>
  );
}
