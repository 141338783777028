import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useMe } from "./context/MeContext";
import Home from "./pages/HomePage";
import GenericError from "./components/GenericError";
import Navigation from "./components/Navigation";
import React, { ReactNode } from "react";
import ExperienceListPage from "./pages/Experiences/ExperienceListPage";
import ExperienceDetailPage from "./pages/Experiences/ExperienceDetailPage";
import ExperiencesParentPage from "./pages/Experiences/ExperiencesParentPage";
import AdminUserListPage from "./pages/Admin/AdminUserListPage";
import AdminCompanyListPage from "./pages/Admin/AdminCompanyListPage";
import AdminExperienceListPage from "./pages/Admin/AdminExperienceListPage";
import SdkPage from "./pages/SdkPage";
import AdminPage from "./pages/AdminPage";
import AdminHomePage from "./pages/Admin/AdminHomePage";
import AdminExperienceDetailPage from "./pages/Admin/AdminExperienceDetailPage";
import AdminCompanyDetailPage from "./pages/Admin/AdminCompanyDetailPage";
import AdminUserDetailPage from "./pages/Admin/AdminUserDetailPage";
import SupportPage from "./pages/SupportPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import AccountPage from "./pages/AccountPage";
import DocumentationPage from "./pages/DocumentationPage";
import ProfileCompanyPage from "./pages/Profile/ProfileCompanyPage";
import VideoPage from "./pages/VideoPage";
import RecordingPage from "./pages/RecordingPage";
import AdminVideoPage from "./pages/Admin/AdminVideoPage";
import AdminAssetsListPage from "./pages/Admin/AdminAssetsListPage";

// Define the type for Layout props
interface LayoutProps {
  children?: ReactNode;
}

// Layout component to wrap pages with Navigation
const Layout: React.FC<LayoutProps> = () => (
  <div className="grid grid-cols-12 sm:divide-x sm:divide-x-1 divide-solid divide-stone-900 sm:h-screen overflow-x-hidden">
    <div className="col-span-12 sm:col-span-4 xl:col-span-2">
      <Navigation />
    </div>
    <div className="col-span-12 sm:col-span-8 xl:col-span-10 h-screen overflow-y-hidden overflow-x-hidden">
      <div className="py-4 px-16 overflow-x-hidden overflow-y-auto h-full">
        <Outlet />
      </div>
    </div>
  </div>
);

export default function RoutedApp() {
  const { me, isLoading: meLoading } = useMe();

  if (!me) {
    return (
      <GenericError errorMessage="There was a problem with your user account." />
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="experiences" element={<ExperiencesParentPage />}>
            <Route path="" element={<ExperienceListPage />} />
            <Route path=":companyId" element={<Outlet />}>
              <Route path=":experienceId" element={<ExperienceDetailPage />} />
            </Route>
          </Route>
          <Route path="videos" element={<VideoPage />} />
          <Route
            path="companies/:companyId/experiences/:experienceId/recordings/:recordingId"
            element={<RecordingPage />}
          />
          <Route path="sdk" element={<SdkPage />} />
          <Route path="admin" element={<AdminPage />}>
            <Route path="" element={<AdminHomePage />} />
            <Route path="users" element={<AdminUserListPage />} />
            <Route path="users/:userId" element={<AdminUserDetailPage />} />
            <Route path="companies" element={<AdminCompanyListPage />} />
            <Route
              path="companies/:companyId"
              element={<AdminCompanyDetailPage />}
            />
            <Route path="experiences" element={<AdminExperienceListPage />} />
            <Route
              path="experiences/:companyId/:experienceId"
              element={<AdminExperienceDetailPage />}
            />
            <Route path="videos" element={<AdminVideoPage />} />
            <Route path="assets" element={<AdminAssetsListPage />} />
          </Route>
          <Route path="analytics" element={<AnalyticsPage />} />
          <Route path="profile" element={<AccountPage />} />
          <Route
            path="profile/company/:companyId"
            element={<ProfileCompanyPage />}
          />
          <Route path="support" element={<SupportPage />} />
          <Route path="documentation" element={<DocumentationPage />} />
        </Route>

        {/* Catch-all route to redirect */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
