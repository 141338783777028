import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Asset } from "../../types/Asset";
import { FormEvent, useEffect, useState } from "react";
import PositiveButton from "../PositiveButton";
import useAssetService from "../../services/AssetService";

interface DesktopAppFormProps {
  suggestedVersion: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSaved?: (asset: Asset) => void;
  onCancel?: () => void;
}

export default function DesktopAppForm({
  suggestedVersion,
  onSaved,
  open,
  setOpen,
  onCancel,
}: DesktopAppFormProps) {
  const [formData, setFormData] = useState({
    version: suggestedVersion,
    whatsNew: "",
  });

  const {uploadDesktopApp} = useAssetService();

  const [file, setFile] = useState<File | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (formData.version.trim() === "") {
      newErrors.version = "Version is required";
      isValid = false;
    }

    if (formData.whatsNew.trim() === "") {
      newErrors.whatsNew = "What's new is required";
      isValid = false;
    }

    if (!file) {
      newErrors.file = "File upload is required";
      isValid = false;
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  useEffect(() => {
    if (!open) {
      setFormData({
        version: suggestedVersion,
        whatsNew: "",
      });
      setFile(null);
      setValidationErrors({});
      setSubmitted(false);
    }
  }, [open]);

  const saveDesktopApp = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    // Save.

    uploadDesktopApp(formData.version, formData.whatsNew, file!).then((asset:Asset) => {
      if (onSaved) {
        onSaved(asset);
      }

      setFormData({
        version: "",
        whatsNew: "",
      });

      if (!onSaved) {
        setOpen(false);
      }
    }).catch((error:any) => {
      console.error("Error uploading desktop app:", error);
      const newErrors: any = {
        name: "Failed to save new desktop app. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    }).finally(() => {
      setIsSaving(false);
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setFile(selectedFile);
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  return (
    <Dialog open={open} onClose={() => {
      if (!isSaving) {
        setOpen(false);
      }
    }} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-slate-900 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <DialogTitle
              as="h3"
              className="text-base leading-6 text-white pb-4"
            >
              Create a new desktop app version
            </DialogTitle>
            <form
              onSubmit={saveDesktopApp}
              className="card p-8 flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
            >
              <div className="sm:col-span-4">
                <label htmlFor="fileUpload" className="block leading-6">
                  Upload File:
                </label>
                <div className="mt-2">
                  <input
                    disabled={isSaving}
                    type="file"
                    name="fileUpload"
                    accept="*"
                    onChange={handleFileChange}
                    className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                      "file"
                    )}`}
                  />
                </div>
                {validationError("file") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("file")}
                  </div>
                )}
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="version" className="block leading-6">
                  Version:
                </label>
                <div className="mt-2">
                  <input
                    disabled={isSaving}
                    type="text"
                    name="version"
                    placeholder="Version*"
                    value={formData.version}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                      "version"
                    )}`}
                  />
                </div>
                {validationError("version") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("version")}
                  </div>
                )}
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="whatsNew" className="block leading-6">
                  What&apos;s new:
                </label>
                <div className="mt-2">
                  <textarea
                    disabled={isSaving}
                    name="whatsNew"
                    placeholder="Give a brief description of what is new in this version"
                    value={formData.whatsNew}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                      "whatsNew"
                    )}`}
                  />
                </div>
                {validationError("whatsNew") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("whatsNew")}
                  </div>
                )}
              </div>
              {/* Submit Button */}
              <div className="sm:col-span-4 flex items-center gap-2 justify-end">
                <PositiveButton type="submit" disabled={isSaving}>
                  {isSaving ? "Uploading..." : "Upload"}
                </PositiveButton>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
