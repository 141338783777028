import { useMe } from "../context/MeContext";
import CreateFirstOrganisation from "../components/CreateFirstOrganisation";
import { useEffect, useState } from "react";
import { Company } from "../types/Me";
import CompaniesPanel from "../components/Home/CompaniesPanel";
import Navigation from "../components/Navigation";
import ExperienceList from "../components/Experiences/ExperienceList";

function Greeting() {

    const {me} = useMe();

    // Work out a greeting based on time of day.
    let greeting = "Hello";

    const now = new Date();

    if (now.getHours() < 12) {
        greeting = "Good morning";
    } else if (now.getHours() < 18) {
        greeting = "Good afternoon";
    } else {
        greeting = "Good evening";
    }

    return <h1 className="text-4xl">{greeting} {me?.givenName}</h1>
}

function UserHome() {
    return (
        <>
            <Greeting />
            <ExperienceList header={<h2 className="text-2xl">Your experiences</h2>} />
        </>
    )
}

function AdminHome() {
    return (
        <>
            <Greeting />
            <ExperienceList header={<h2 className="text-2xl">Your experiences</h2>} />
        </>
    )
}

export default function HomePage(){

    const {me, refreshMe, isAdmin} = useMe();
    
    const [organisation, setOrganisation] = useState<Company|null>(null);

    useEffect(() => {
        if (me && me.memberships.length > 0) {
            setOrganisation(me.memberships[0].company);
        }
    }, [me]);

    return <>
        <div className="text-white flex flex-col gap-2">
            {!isAdmin && <UserHome />}
            {isAdmin && <AdminHome />}
        </div>
    </>
}