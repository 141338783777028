import { FormEvent, useState } from "react";
import { Experience } from "../../types/Experience";
import useUrlUtilities from "../../utilities/UrlUtilities";
import useExperienceService from "../../services/ExperienceService";
import SimpleAlert from "../SimpleAlert";
import { useNavigate } from "react-router-dom";
import LoadingEllipsis from "../LoadingEllipsis";
import PositiveButton from "../PositiveButton";
import NegativeButton from "../NegativeButton";
import { Company, UpdateCompanyDetails } from "../../types/Company";
import useCompanyService from "../../services/CompanyService";
import { useMe } from "../../context/MeContext";

interface CompanyDetailFormProps {
  company: Company;
  onSaved?: (company: Company) => void;
}

export default function CompanyDetailForm({
  company,
  onSaved,
}: CompanyDetailFormProps) {
  const [formData, setFormData] = useState({
    name: company.name,
    websiteUrl: company.websiteUrl,
    description: company.description,
    type: company.type
  });

  const { isValidUrl } = useUrlUtilities();
  const navigate = useNavigate();
  const {me} = useMe();

  const isAuthorized = me?.roles.includes("administrator") || 
    me?.memberships?.some(m => m.company.id === company.id && m.role.toLowerCase() === "owner") ||
    me?.memberships?.some(m => m.company.id === company.id && m.role.toLowerCase() === "manager");

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isClean, setIsClean] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const { updateCompany } = useCompanyService();

  const deleteThisCompany = async () => {
    try {
      setIsDeleting(true);
      // await deleteExperience(experience.companyId, experience.id);
      setDeleteAlertOpen(false);
      navigate(-1);
    } catch (error) {
      const newErrors: any = {
        name: "Failed to delete company. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};
    
    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if ((formData.websiteUrl ?? "").trim() !== "") {
      // Check the website url is a valid url using regex.
      if (!isValidUrl(formData.websiteUrl!.trim())) {
        newErrors.websiteUrl =
          "Url is not a valid url in the form https://company.com";
        isValid = false;
      }
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  const saveCompany = async (e: FormEvent) => {
    e.preventDefault();

    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    try {
      // Save the company
      const itemToSave: UpdateCompanyDetails = {
        companyId: company.id,
        ...company,
        ...formData,
      };

      const newCompany = await updateCompany(itemToSave);

      if (onSaved) {
        onSaved(newCompany);
      }

      setIsClean(true);
    } catch (error) {
      const newErrors: any = {
        name: "Failed to save company. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setIsClean(false);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  const getTermsSignedOn = () => {
    if (company.termsAgrementSignedOn) {

      // Convert the value ("2024-12-20T12:47:28.0243695Z") to a date string.
      var date = new Date(company.termsAgrementSignedOn);
      
      return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
    }
    return "Not signed";
  }

  if (isDeleting) {
    return (
      <div className="flex items-center bg-gray-30">
            <div className="flex flex-col items-center space-y-4">
                <h1 className="text-lg text-white">
                    Deleting
                    <LoadingEllipsis />
                </h1>
            </div>
        </div>
    )
  }

  return (
    <>
      <form
        onSubmit={saveCompany}
        className="card p-8 form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
      >
        <div className="sm:col-span-4">
          <label htmlFor="name" className="block leading-6">
            Company name:
          </label>
          <div className="mt-2">
            <input
              disabled={isSaving || !isAuthorized}
              type="text"
              name="name"
              placeholder="Company name *"
              value={formData.name}
              onChange={handleInputChange}
              className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                "name"
              )}`}
            />
          </div>
          {validationError("name") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("name")}
            </div>
          )}
        </div>

        {/* Website URL */}
        <div className="sm:col-span-4">
          <label htmlFor="websiteUrl" className="block leading-6">
            Website:
          </label>
          <div className="mt-2">
            <input
              disabled={isSaving || !isAuthorized}
              type="url"
              name="websiteUrl"
              placeholder="https://supergamestud.io"
              value={formData.websiteUrl ?? ""}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            />
          </div>
          {validationError("websiteUrl") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("websiteUrl")}
            </div>
          )}
        </div>

        {/* Short description */}
        <div className="sm:col-span-4">
          <label htmlFor="description" className="block leading-6">
            Short description:
          </label>
          <div className="mt-2">
            <input
              type="text"
              disabled={isSaving || !isAuthorized}
              placeholder="Short description"
              name="description"
              value={formData.description ?? ""}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            />
          </div>
          {validationError("description") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("description")}
            </div>
          )}
        </div>

        {/* Agreement */}
        {company && company.termsAgreementSignedByUserId && (
          <>
            <div className="sm:col-span-4">
              <label className="block leading-6"><a href="https://beamxr.io/developerterms/" target="_blank" rel="noreferrer"  className="underline">Developer terms and conditions</a> signed on:</label>
              <div className="mt-2">
                <input type="text" disabled value={getTermsSignedOn()} className="w-full px-3 py-2 border rounded text-black" />
              </div>
            </div>
            <div className="sm:col-span-4">
              <label className="block leading-6"><a href="https://beamxr.io/developerterms/" target="_blank" rel="noreferrer"  className="underline">Developer terms and conditions</a> signed by:</label>
              <div className="mt-2">
                <input type="text" disabled value={company.termsAgreementSignedByEmail} className="w-full px-3 py-2 border rounded text-black" />
              </div>
            </div>
        </>
        )}


        {/* Submit Button */}
        <div className="sm:col-span-4 flex items-center gap-2 justify-end">
          <PositiveButton
            type="submit"
            disabled={isSaving || isClean || !isAuthorized}
          >
            {isSaving ? "Saving..." : isClean ? "Saved" : "Save changes"}
          </PositiveButton>
        </div>
      </form>
      <SimpleAlert
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        title={`Are you sure you want to delete ${company.name}?`}
        message="This action cannot be undone and any experiences in this company will stop working."
        confirmText={`Delete ${company.name}`}
        onConfirm={deleteThisCompany}
      />
    </>
  );
}
