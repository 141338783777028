import { useState } from "react";
import PageTitle from "../components/PageTitle";
import { Asset } from "../types/Asset";
import DesktopAppList from "../components/Assets/DesktopAppList";
import { version } from "os";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

export default function SdkPage() {
  const [desktopApp, setDesktopApp] = useState<Asset | null>(null);

  const isProduction = API_BASE_URL.toLowerCase() == "https://api.beamxr.io";
  const versions = [
    {
      version: "1.2.2",
      releaseDate: "2024-12-23",
      description: "This version offers lip-sync perfect harmony between the video in audio recordings. Also significant reduction in time to process video after a session.",
    },
    {
      version: "1.2.0",
      releaseDate: "2024-12-20",
      description: "We've added some minor bug fixes for Windows 10 and better synchronisation with audio over time of the local recordings.",
    }]

  const urlBuilder = (version: string) => {
    return isProduction
      ? `https://beamxrpublic.blob.core.windows.net/desktop-app/prod/${version}/BeamXRDesktopInstaller.exe`
      : `https://beamxrpublic.blob.core.windows.net/desktop-app/uat/${version}/BeamXRDesktopInstaller.exe`;
  };

  const unitySdks = [
    {
      name: "Core SDK",
      description:
        "The BeamXR Core Unity SDK allows you to easily integrate BeamXR into your Unity projects and get streaming. Get it on GitHub.",
      url: "https://github.com/BeamXR-io/io.beamxr.unity.streaming",
      buttonText: "Visit GitHub",
    },
    {
      name: "Twitch SDK",
      description:
        "The BeamXR Twitch Unity SDK allows your players to interact with their Twitch viewers directly in your game. Get it on GitHub.",
      url: "https://github.com/BeamXR-io/io.beamxr.unity.twitch",
      buttonText: "Visit GitHub",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <section role="heading" className="grid grid-cols-1 xl:grid-cols-2">
        <PageTitle>SDK &amp; Tools</PageTitle>
      </section>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
        <div className="flex flex-col gap-4">
          <section role="heading" className="flex flex-col gap-4">
            <h2 className="sm:text-xl xl:text-2xl">Desktop apps</h2>
            {versions.map((app, index) => (
              <div className="w-full card flex flex-col">
                <div className="p-4">
                  <div className="flex flex-row justify-between">
                  <h3 className="text-2xl">{app.version}</h3>
                  <div className="text-sm">Released: {app.releaseDate}</div>
                  </div>
                  <p>{app.description}</p>
                </div>
                <div className="grid grid-cols-1">
                  <button
                    className="enabled:text-cyan-300 enabled:hover:bg-cyan-300/25 p-2 rounded-none rounded-br-lg no-transform"
                    onClick={() => {
                      window.open(urlBuilder(app.version), "_blank");
                    }}
                  >
                    Download desktop app {app.version}
                  </button>
                </div>
              </div>
            ))}
          </section>
        </div>
        <div className="flex flex-col gap-4">
          <section role="heading" className="flex flex-col gap-4">
            <h2 className="sm:text-xl xl:text-2xl">Unity SDKs</h2>
            {unitySdks.map((sdk, index) => (
              <div className="w-full card flex flex-col">
                <div className="p-4">
                  <h3 className="text-2xl">{sdk.name}</h3>
                  <p>{sdk.description}</p>
                </div>
                <div className="grid grid-cols-1">
                  <button
                    className="enabled:text-cyan-300 enabled:hover:bg-cyan-300/25 p-2 rounded-none rounded-br-lg no-transform"
                    onClick={() => {
                      window.open(sdk.url, "_blank");
                    }}
                  >
                    {sdk.buttonText}
                  </button>
                </div>
              </div>
            ))}
          </section>
        </div>
        <div className="flex flex-col gap-4">
          <section role="heading" className="flex flex-col gap-4">
            <h2 className="sm:text-xl xl:text-2xl">Unreal SDKs</h2>

            <div className="w-full card flex flex-col">
              <div className="p-4">
                <h3 className="text-2xl">Coming soon</h3>
                <p>
                  More tools are coming soon, to keep updated join our discord
                </p>
              </div>
              <div className="grid grid-cols-1">
                <button
                  className="enabled:text-cyan-300 enabled:hover:bg-cyan-300/25 p-2 rounded-none rounded-br-lg no-transform"
                  onClick={() => {
                    window.open(
                      "https://discord.com/invite/THkkkGdZ2b",
                      "_blank"
                    );
                  }}
                >
                  Join the discord
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
